import React from 'react'
import { Breadcrumb, BreadcrumbItem, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import './breadcrumb.scss'
function BreadCrumbs() {
  return (
    <div className="crumb d-none d-md-block">
      <Breadcrumb>
        <Container>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/media">Media</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/media/corporatenews">Cooperate News</Link>
          </BreadcrumbItem>

          <BreadcrumbItem active>Full Article</BreadcrumbItem>
        </Container>
      </Breadcrumb>
      {/* Home&ensp;> Media&ensp;> Corporate News&ensp;>
            <span className='red'> Full Article</span> */}
    </div>
  )
}

export default BreadCrumbs
