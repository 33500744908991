import React from 'react'
import { Container, Row, Col } from 'reactstrap'
// import '../thecompany.scss';

function TheCompany() {
  return (
    <div className="the-company ">
      <Container>
        <div className="company-intro">
          <div className="detail-text text-muted">
            {/* <p>
              F1Soft Group is an ecosystem of digital products and services that
              facilitates Nepali consumers’ access to financial services and
              enables their inclusion in the formal digital economy, in simple,
              affordable and secure ways.
            </p>

            <p>
              We are a diversified Digital Financial Services (DFS) holding 
              company with over 19 years of operating experience. We have 
              invested over a decade innovating DFS products and services and 
              are proud to have developed Nepal’s first ever Online Payment 
              Gateway and Digital Wallet. We are the pioneers in mobile banking 
              and a trusted technology partner to over 55 Nepali banks and 
              financial institutions.
              <br />
              <br /> Today, we are a team of 1200+ young professionals with
              expertise in technology and management spread across 12 verticals
              but with a common core in digital finance. Our people are skilled
              engineers, innovators and creative disruptors, all working towards
              a shared vision to serve every Nepali citizen every single day.
            </p> */}
            
            <p>
              F1Soft Group is an ecosystem of digital products and services that
              facilitates consumer’s access to financial services and
              enables their inclusion in the formal digital economy, in simple,
              affordable and secure ways.
            </p>

            <p>
              We are a diversified Digital Financial Services (DFS) holding 
              company with over 20 years of operating experience. We have 
              invested over a decade innovating DFS products and services and 
              are proud to have developed Nepal’s first ever Online Payment 
              Gateway and Digital Wallet. We are the pioneers in mobile banking 
              and a trusted technology partner to over 69 Banks and 
              Financial Institutions.
              <br />
              <br /> Today, we are a team of 1300+ young professionals with
              expertise in technology and management spread across 14 verticals
              but with a common core in digital finance. Our people are skilled
              engineers, innovators and creative disruptors, all working towards
              a shared vision to serve every Nepali citizen every single day.
            </p>
          </div>
        </div>
        {/* <div className="company-goal">
          <Row>
            <Col md="6">
              <div className="goal-detail">
                <div className="goal-title">our mission</div>
                <div className="goal-description text-muted">
                  To connect people to digital financial services across Nepal
                  in simple, affordable and secure ways
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="goal-detail">
                <div className="goal-title">our vision</div>
                <div className="goal-description text-muted">
                  To become a household brand used by every Nepali to meet their
                  daily digital consumption needs.
                </div>
              </div>
            </Col>
            <Col mad="4">
              <div className="company-values">
                <ul>
                  <h5> our values</h5>
                  <li>We start with Trust</li>
                  <li>We are fueled by Integrity</li> 
                  <li>We believe in Collaboration</li>
                  <li>We work with Grit</li>
                  <li>We work as Enablers</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>

      <div class="value">
        <Container>
          <div class="section-head style-4 text-center mb-5">
            <h2 class="mb-10 fs-2"><span>Tiger on a Cliff</span></h2>
            <h4 class="mb-20 ">Our Core Values (TIGER) standing atop our Culture Pillars (CLIFF)</h4>
          </div>

          <div class="row mb-5" id="main">
            <div class="col-md-3 fs-2 d-flex flex-column align-items-start justify-content-center mb-3">
              <div data-aos="fade-right"> <span>T</span>rust </div>
              <div data-aos="fade-right"> <span>I</span>ntegrity </div>
              <div data-aos="fade-right"> <span>G</span>rit </div>
              <div data-aos="fade-right"> <span>E</span>mpathy </div>
              <div data-aos="fade-right"> <span>R</span>espect </div>
            </div>

            <div class="col-md-6 mb-3">
              <img class="img-fluid" src="/img/Tiger_on_a_Cliff.png" alt="Tiger on a Cliff"/>
            </div>

            <div class="col-md-3 fs-2 d-flex flex-column align-items-start justify-content-center mb-3">
              <div data-aos="fade-left"> <span>C</span>ollaboration </div>
              <div data-aos="fade-left"> <span>L</span>earning </div>
              <div data-aos="fade-left"> <span>I</span>nnovation </div>
              <div data-aos="fade-left"> <span>F</span>air </div>
              <div data-aos="fade-left"> <span>F</span>un </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-1 order-sm-1 mt-5">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>We start with <span class="fs-4 ms-1"> <span>T</span>rust </span></p>
                  <p>We are committed to honesty and reliability in all our relationships to ensure that we trust each other and our customers trust us.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-2 order-sm-6 mt-5">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>A Culture of <span class="fs-4 ms-1"> <span>C</span>ollaboration </span></p>
                  <p>Individuals work collectively towards a greater common goal by sharing knowledge, ideas, and resources with mutual trust for one another.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-3 order-sm-2">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>We are driven by <span class="fs-4 ms-1"> <span>I</span>ntegrity </span></p>
                  <p>We are guided by the highest moral and ethical standards and take full responsibility of our actions while caring deeply about the outcomes.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-4 order-sm-7">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>A Culture of <span class="fs-4 ms-1"> <span>L</span>earning </span></p>
                  <p>Gritty Individuals and teams embrace each challenge and setback as an opportunity to adapt, re-strategize, and grow.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-5 order-sm-3">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>We work with <span class="fs-4 ms-1"> <span>G</span>rit </span></p>
                  <p>We strive for excellence and remain focused and resilient to overcome all challenges even when faced with adversity.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-6 order-sm-8">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>A Culture of <span class="fs-4 ms-1"> <span>I</span>nnovation </span></p>
                  <p>Everyone is focused on creating leading-edge solutions based on empathy by putting customers at the core of everything.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-7 order-sm-4">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>We embrace <span class="fs-4 ms-1"> <span>E</span>mpathy </span></p>
                  <p>We put ourselves in the shoes of others to understand their feelings and perspectives to create an environment for human-centric relationships and customer-centric innovations.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-8 order-sm-9">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>A Culture that is <span class="fs-4 ms-1"> <span>F</span>air </span></p>
                  <p>Individuals demonstrating Integrity to participate in interactions and decisions that are impartial, unprejudiced, and unbiased.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-9 order-sm-5">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>We stand for <span class="fs-4 ms-1"> <span>R</span>espect </span></p>
                  <p>We recognize that every individual is unique and treat everyone with dignity regardless of gender, class, opinion, or creed.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 d-flex align-items-center justify-content-center order-10 order-sm-10">
              <div class="card border-0 shadow p-2 mb-3 h-100">
                <div class="card-body">
                  <p>A Culture that is <span class="fs-4 ms-1"> <span>F</span>un </span></p>
                  <p>Individuals feeling valued and treating each other with Respect to nurture a vibrant and inclusive workplace with an enjoyable experience.</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default TheCompany
